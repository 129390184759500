import { AbstractControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

//import { FormGroup } from '@angular/forms';

export function PricingRemarkValidator(mrp: string, pricingRemark: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[mrp];
        const matchingControl = formGroup.controls[pricingRemark];

        if (matchingControl.errors && !matchingControl.errors.errMsg) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if (parseFloat(control.value) > 0 && matchingControl.value !== '') {
            if (matchingControl.value !== 'MRP is available') {
                return matchingControl.setErrors({ errMsg: true });
            }
        } else if ((parseFloat(control.value) === 0 || control.value !== '') && matchingControl.value !== '') {
            if (matchingControl.value === 'MRP is not available as of now' || matchingControl.value === 'Price on request') {
                return null;
            } else {
                return matchingControl.setErrors({ errMsg: true });
            }
        } else if (control.value === '' && matchingControl.value === 'MRP is available') {
            return matchingControl.setErrors({ errMsg: true });
        } else if (matchingControl.value === '') {
            return matchingControl.setErrors({ errMsg: true });
        }
        return null;
    };
}

// export class PricingRemarkValidator {

//     public static validate(formGroup: FormGroup) {
//         const msnMrp: string = this.formGrp.control.get('msnMrp').value; // get password from our password form control
//         const pricingRemark: string = control.get('pricingRemark').value;

//         if (parseFloat(msnMrp) > 0 && pricingRemark !== '') {
//             if (pricingRemark !== 'MRP is available') {
//                 return control.get('pricingRemark').setErrors({ errMsg: true });
//             }
//         } else if ((parseFloat(msnMrp) === 0 || control.value !== '') && pricingRemark !== '') {
//             if (pricingRemark === 'MRP is not available as of now' || pricingRemark === 'MRP is not applicable') {
//                 return control.get('pricingRemark').setErrors({ errMsg: false });
//             }
//         }
//         return control.get('pricingRemark').setErrors({ errMsg: true });
//     }
// }

